import axios from "axios";

export default {
  async search(fromDate, toDate) {
    return await axios
      .get(`internal-transfer-dispatch-inspection?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getById(id) {
    return await axios
      .get("internal-transfer-dispatch-inspection/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  create(data) {
    return axios
      .post("internal-transfer-dispatch-inspection", data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  update(id, data) {
    return axios
      .patch("internal-transfer-dispatch-inspection/" + id, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
