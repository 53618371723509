<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="green" indeterminate></v-progress-circular></v-overlay>
    <v-container>
      <v-card class="card-shadow border-radius-xl mt-6">
        <v-card-text class="px-3 py-0">
          <v-row class="m-0">
            <v-col cols="12" md="4">
              <v-menu ref="mnu_frm_date" v-model="mnu_frm_date" :close-on-content-click="false"
                :return-value.sync="mnu_frm_date" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="frm_date" readonly v-bind="attrs" v-on="on" hide-details="auto" class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                      
                      " dense flat filled solo height="43" placeholder="Date" persistent-hint></v-text-field>
                </template>
                <v-date-picker color="green lighten-1" header-color="primary" v-model="frm_date" no-title scrollable
                  :max="to_date">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnu_frm_date = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.mnu_frm_date.save(frm_date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu ref="mnu_to_date" v-model="mnu_to_date" :close-on-content-click="false"
                :return-value.sync="mnu_to_date" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="to_date" readonly v-bind="attrs" v-on="on" hide-details="auto" class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                     
                      " dense flat filled solo height="43" placeholder="Date" persistent-hint></v-text-field>
                </template>
                <v-date-picker color="green lighten-1" header-color="primary" v-model="to_date" no-title scrollable
                  :min="frm_date">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnu_to_date = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.mnu_to_date.save(to_date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" align-self="center">
              <v-btn color="primary" elevation="0" :ripple="false" height="43" dark
                class="text-capitalize btn-ls btn-primary bg-success py-3 px-6" @click="search">
                Search
              </v-btn></v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="card-shadow cstmcard-bg border-radius-xl mt-6" v-if="data.length > 0">
        <v-row style="margin:0px;">

          <v-col v-for="item in data" :key="item.id" class="cstm_card">
            <v-card @click="viewDetails(item)">

              <v-list-item style="padding:0px;">
                <v-subheader :inset="inset">
                  Code :
                </v-subheader>
                <v-list-item-content class="align-end"><b
                    style="color: rgba(0, 0, 0, 0.6);font-size: 14px; margin-top: 2px;">{{
                      item.dispatch_code
                    }}</b></v-list-item-content>
              </v-list-item>

              <v-list-item style="padding:0px;">
                <v-subheader :inset="inset">
                  Qty :
                </v-subheader>
                <v-list-item-content class="align-end"><b
                    style="color: rgba(0, 0, 0, 0.6);font-size: 14px; margin-top: 2px;">{{
                      item.ordered_quantity
                    }}</b></v-list-item-content>
              </v-list-item>

              <v-list-item style="padding:0px;">
                <v-subheader :inset="inset">
                  Warehouse :
                </v-subheader>
                <v-list-item-content class="align-end"><b
                    style="color: rgba(0, 0, 0, 0.6);font-size: 14px; margin-top: 2px;">{{
                      item.name
                    }}</b></v-list-item-content>
              </v-list-item>

              <v-list-item style="padding:0px;">
                <v-subheader :inset="inset">
                  Date :
                </v-subheader>
                <v-list-item-content class="align-end"><b
                    style="color: rgba(0, 0, 0, 0.6); font-size: 14px; margin-top: 2px;">{{
                      formatDate(item.dispatch_date)
                    }}</b></v-list-item-content>
              </v-list-item>
              <v-chip class="itdi_stat" label small :ripple="false" :color="OrderStatus(item).color">
                {{ OrderStatus(item).label }}
              </v-chip>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import { mapGetters } from "vuex";

export default {
  name: "ITDI List",
  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),
  },
  data() {
    return {
      overlay: false,
      isMounted: false,

      frm_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_frm_date: false,

      to_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_to_date: false,
      data: [],
    };
  },
  methods: {
    async onInit() { },
    async search() {
      this.data = await api.search(this.frm_date, this.to_date);
      console.log(this.data);
      if (this.data.length == 0) {
        this.showNoDataAlert("No Data Found")
      }
    },
    async viewDetails(item) {
      this.overlay = true;
      this.$router.push({
        name: "Internal Transfer Dispatch Inspection Details",
        params: { id: item.id },
        query: {
          currentPage: this.currentPage,
        },
      });
      this.overlay = false;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },

    OrderStatus(item) {
      if (item.status === 'COMPLETED') {
        return { label: "COMPLETED", color: "#38bd34" };
      } else if (item.status === 'DRAFT') {
        return { label: "DRAFT", color: "#ff6500" };
      } else {
        return { label: "PENDING", color: "#ffb324" };
      };
    },
  },
  async mounted() {
    await this.store.dispatch("setLoading", true);
    await this.onInit();
    this.isMounted = true;
    await this.store.dispatch("setLoading", false);
  },


};
</script>
<style>
.cstm_card {
  border-radius: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 15px -7px #6e6e6e;
  border: 1px solid #d6d6d6;
}

.itdi_stat {
  float: right !important;
  color: #fff !important;
  font-size: 10px !important;
}

.cstmcard-bg {
  padding: 25px 15px 15px 25px;
}

.cstm_card .v-subheader {
  padding: 0px 7px 0px 5px;
}


@media screen and (max-width: 575px) {

  .cstm_card {
    margin-right: 0px;
  }

  .cstmcard-bg {
    padding: 10px;
  }

  .cstm_card b {
    font-size: 11px !important;
  }

}
</style>
